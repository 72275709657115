import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 13 13' {...props} fill='none'>
    <path
      d='M7.394 1.211h5.347c.143 0 .259-.118.259-.263a.261.261 0 0 0-.26-.264H7.395A.934.934 0 0 0 6.5 0a.933.933 0 0 0-.894.684H.259A.261.261 0 0 0 0 .948c0 .145.116.263.26.263h5.346c.088.311.329.557.635.647v2.457h-2.8c-1.156 0-2.093.953-2.093 2.128v4.428c0 1.176.936 2.129 2.093 2.129h6.12c1.155 0 2.092-.953 2.092-2.129V6.443c0-1.175-.937-2.129-2.093-2.129h-2.8V1.857a.94.94 0 0 0 .634-.646ZM6.5 7.352H3.434a.261.261 0 0 1-.26-.263c0-.146.117-.264.26-.264H6.5c.143 0 .26.118.26.264a.262.262 0 0 1-.26.263Zm3.027 1.536H3.434a.261.261 0 0 1-.26-.264c0-.146.117-.264.26-.264h6.092c.144 0 .26.118.26.264a.261.261 0 0 1-.26.264Zm0 1.45H3.434a.261.261 0 0 1-.26-.264c0-.146.117-.263.26-.263h6.092c.144 0 .26.117.26.263a.261.261 0 0 1-.26.264Z'
      fill={props.fill}
    />
  </Svg>
)

Icon.defaultProps = {
  fill: 'white',
}

export default Icon
