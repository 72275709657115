import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
    <Svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6335 13.1108C16.3734 11.8107 15.4634 11.1606 14.277 10.8031C13.9357 10.7056 13.5782 10.6406 13.2369 10.5593C12.4893 10.4131 11.7255 10.3155 10.9941 10.088C10.2791 9.87675 10.0516 9.40545 10.1003 8.64162C10.1491 8.04031 10.5066 7.55276 11.1567 7.45525C11.7255 7.37399 12.343 7.32524 12.8956 7.4715C13.3019 7.58527 13.7732 7.95905 13.9682 8.33284C14.2607 8.90165 14.5858 9.17793 15.1221 9.04791C15.3008 9.04791 15.3983 9.04791 15.4796 9.04791C16.2759 9.04791 16.471 8.77164 16.2272 8.00781C15.8696 6.91895 15.1546 6.10636 14.0657 5.81383C13.3994 5.63507 13.3019 5.31003 13.3181 4.74122C13.3506 3.58736 13.2206 3.47359 12.083 3.47359C11.9855 3.47359 11.9042 3.47359 11.823 3.47359C11.3517 3.44109 11.1242 3.68487 11.0592 4.09116C11.0104 4.44869 11.0104 4.80623 11.0267 5.16377C11.0429 5.4563 10.9291 5.55381 10.6529 5.63507C10.2141 5.76508 9.75902 5.91134 9.36898 6.15512C8.1176 6.90269 7.6138 8.04031 7.77632 9.47046C7.92259 10.7868 8.73517 11.5832 9.95404 11.9895C10.2953 12.1032 10.6366 12.1845 10.9941 12.2657C11.7905 12.4445 12.6193 12.542 13.3994 12.802C14.5208 13.1596 14.7645 14.476 13.9194 15.3048C12.7493 16.4424 10.3441 15.8899 9.85653 14.346C9.71027 13.9072 9.45024 13.6959 8.9952 13.7121C8.76767 13.7121 8.5239 13.6959 8.29637 13.7121C7.67881 13.7609 7.46754 14.0534 7.6138 14.6385C7.97134 16.1824 8.96269 17.125 10.4903 17.4988C10.9941 17.6125 11.1567 17.8076 11.1242 18.3114C11.0267 19.9203 11.3679 19.774 12.6681 19.774C13.0581 19.774 13.2856 19.579 13.3344 19.2215C13.3831 18.8802 13.4156 18.5389 13.3831 18.2139C13.3669 17.8076 13.4969 17.6288 13.9032 17.4988C14.407 17.3363 14.9108 17.0925 15.3171 16.7837C16.536 15.8411 16.926 14.5897 16.6335 13.1108Z" fill={props.fill}/>
        <path d="M21.0215 3.2623C19.6239 1.79965 17.9662 0.743294 16.0647 0.0769763C15.7072 -0.053037 15.3822 -0.0530369 15.2197 0.353255C14.8459 1.23084 15.2359 2.17344 16.1135 2.54723C19.7701 4.10739 22.1591 7.68275 22.1591 11.6644C22.1591 15.7273 19.7376 19.3514 15.9672 20.8954C15.3822 21.1391 15.0896 21.4642 15.1709 22.098C15.1872 22.2442 15.1709 22.3905 15.1709 22.5205C15.1872 23.2681 15.4309 23.4469 16.146 23.2031C21.0865 21.5454 24.4018 16.9299 24.4018 11.7132C24.3856 8.54409 23.1992 5.58629 21.0215 3.2623Z" fill={props.fill}/>
        <path d="M8.31289 20.7819C4.59126 19.108 2.55981 16.1989 2.28353 12.1685C1.991 7.95933 4.445 4.14019 8.32915 2.49877C8.99547 2.22249 9.40176 1.88121 9.23924 1.21489C9.23924 0.954865 9.25549 0.792349 9.23924 0.629832C9.20674 0.109779 8.91421 -0.0852413 8.41041 0.0772753C0.934642 2.53128 -2.29944 11.2259 1.77973 17.9379C3.32364 20.4894 5.53386 22.212 8.32915 23.1871C8.71919 23.3334 9.04422 23.3009 9.20674 22.8621C9.54802 21.952 9.19048 21.1719 8.31289 20.7819Z" fill={props.fill}/>
    </Svg>
)

    Icon.defaultProps = {
        fill: 'white',
    }
    
    export default Icon