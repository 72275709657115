import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 13 13' {...props} fill='none'>
    <path
      d='M13 6.592v-.926c-.017-.07-.041-.138-.05-.208C12.68 3.033 10.88.975 8.394.276 7.938.147 7.459.09 6.99 0H6.01c-.064.015-.128.036-.193.045-2.647.333-4.46 1.678-5.417 4.02C.192 4.572.13 5.131 0 5.666v.926c.017.07.041.138.05.208.268 2.412 2.066 4.48 4.529 5.176.464.131.953.19 1.43.282h.982c.064-.015.128-.036.193-.044 2.696-.343 4.517-1.727 5.459-4.121.187-.477.241-1 .357-1.5Zm-1.618 1.015L8.114 9.97v-1.6H5.685V6.834h2.446V5.308c1.102.78 2.162 1.528 3.25 2.299Zm-6.505-2.19v1.54L1.62 4.65 4.881 2.29v1.594H7.31v1.534H4.877Z'
      fill={props.fill}
    />
  </Svg>
)

Icon.defaultProps = {
  fill: 'white',
}

export default Icon
