import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 27 12' {...props} fill='none'>
    <path
      d='M6.93713 11.1853H1.54445C1.2463 11.1853 1 10.9273 1 10.615V1.57038C1 1.25803 1.2463 1 1.54445 1H6.93713C7.23529 1 7.48159 1.25803 7.48159 1.57038V10.615C7.48159 10.9273 7.23529 11.1853 6.93713 11.1853Z'
      stroke='white'
      stroke-miterlimit='10'
    />
    <path
      d='M16.1964 11.1853H10.8037C10.5056 11.1853 10.2593 10.9273 10.2593 10.615V1.57038C10.2593 1.25803 10.5056 1 10.8037 1H16.1964C16.4946 1 16.7409 1.25803 16.7409 1.57038V10.615C16.7409 10.9273 16.4946 11.1853 16.1964 11.1853Z'
      fill='white'
      stroke='white'
      stroke-miterlimit='10'
    />
    <path
      d='M25.496 11H20.504C20.228 11 20 10.8733 20 10.72V6.28C20 6.12667 20.228 6 20.504 6H25.496C25.772 6 26 6.12667 26 6.28V10.72C26 10.8733 25.772 11 25.496 11Z'
      fill='white'
    />
    <path
      d='M25.4557 11.1853H20.063C19.7649 11.1853 19.5186 10.9273 19.5186 10.615V1.57038C19.5186 1.25803 19.7649 1 20.063 1H25.4557C25.7538 1 26.0001 1.25803 26.0001 1.57038V10.615C26.0001 10.9273 25.7538 11.1853 25.4557 11.1853Z'
      stroke='white'
      stroke-miterlimit='10'
    />
  </Svg>
)

Icon.defaultProps = {
  fill: 'transparent',
}

export default Icon
