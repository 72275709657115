import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 23 13' {...props} fill='none'>
    <path
      d="M17.312 9.323c-.668-1.14-1.695-1.815-2.925-2.211-.362-.117-.732-.196-1.124-.302.475-.31.864-.687 1.158-1.155a3.685 3.685 0 0 0-.468-4.54 3.686 3.686 0 0 0-5.18-.101c-.74.71-1.133 1.585-1.14 2.604-.012 1.366.596 2.418 1.736 3.207-.06.011-.091.019-.117.026-.88.166-1.717.442-2.468.936-1.11.736-1.804 1.747-2.015 3.072-.02.11-.027.196.098.268 1.834 1.075 3.796 1.758 5.932 1.86 2.509.12 4.8-.611 6.95-1.853.102-.056.136-.12.117-.241a4.39 4.39 0 0 0-.554-1.57Z"
      fill={props.fill}
    />
    <path
      d="M22.61 8.191a2.919 2.919 0 0 0-1.05-1.8c-.535-.445-1.165-.683-1.837-.822-.05-.012-.098-.027-.162-.046 1.384-.962 1.43-2.679.547-3.701a2.462 2.462 0 0 0-3.498-.234c-.517.464-.8 1.045-.827 1.74-.03.931.374 1.652 1.155 2.203-.788.155-1.498.404-2.098.932.034.026.042.034.053.041.068.03.14.057.211.087 1.427.585 2.52 1.517 3.14 2.955.045.105.105.117.204.113 1.456-.057 2.8-.502 4.056-1.223.102-.064.128-.128.106-.245ZM7.523 6.583l.272-.109c-.615-.54-1.324-.792-2.11-.943 1.43-.989 1.438-2.774.491-3.789C5.236.738 3.63.708 2.64 1.678a2.493 2.493 0 0 0 .42 3.849c-.076.019-.137.026-.193.045-.362.117-.743.204-1.087.366-.966.45-1.585 1.193-1.773 2.26-.015.095-.012.163.086.22 1.174.679 2.43 1.116 3.789 1.218.423.03.423.023.615-.362.645-1.298 1.698-2.15 3.026-2.69Z"
      fill={props.fill}
    />
  </Svg>
)

Icon.defaultProps = {
  fill: 'white',
}

export default Icon
