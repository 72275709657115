import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 17 15' {...props}>
    <path
      d='M5.46367 0.173272L0.178869 5.16929C0.0633546 5.2848 -0.0232816 5.45808 0.00559712 5.63135L0.0344758 6.03565C0.0344758 6.18005 0.0922333 6.29556 0.207748 6.41108L3.18226 9.55885C3.41329 9.78988 3.78871 9.81876 4.01974 9.58773L11.095 2.91675C11.2105 2.80124 11.2683 2.62796 11.2683 2.48357V2.02151C11.2683 1.87712 11.2105 1.73272 11.095 1.61721L9.73773 0.173272C9.62221 0.0577574 9.47782 0 9.30455 0H5.89686C5.72358 0 5.57919 0.0577574 5.46367 0.173272Z'
      fill='white'
    />
    <path d='M9.40795 5.72479L7.02441 7.99573L13.6978 15L16.0813 12.729L9.40795 5.72479Z' fill='white' />
  </Svg>
)

Icon.defaultProps = {
  fill: 'white',
}

export default Icon
