import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 12 11' {...props} fill='none'>
    <path
      d='M10.34 7.104c-.732-.019-1.466-.007-2.2-.008-2.068 0-4.137.002-6.205-.005-.16 0-.338-.033-.475-.104a.49.49 0 0 1-.23-.599c.081-.235.298-.386.574-.387.936-.002 1.871 0 2.807 0h.18V4.923c-.033-.006-.062-.015-.09-.015-.985 0-1.97-.01-2.955.005C.745 4.93-.024 5.687 0 6.588c.026.91.817 1.59 1.82 1.59l8.23.001c.059 0 .118-.002.177.002.38.025.648.36.548.681-.088.281-.297.414-.655.414H.614v1.075c.068.002.126.006.184.007.442.004.885.011 1.327.011 2.685 0 5.37.006 8.054-.002 1.236-.005 2.07-1.021 1.745-2.1-.191-.634-.864-1.145-1.583-1.163Z'
      fill={props.fill}
    />
    <path
      d='M6.023 6h.872c.285 0 .57-.004.856.002a.702.702 0 0 0 .512-.195c1.178-1.074 2.359-2.145 3.538-3.218.255-.232.268-.555.025-.794-.137-.135-.285-.26-.428-.39-.434-.394-.874-.784-1.302-1.184-.306-.288-.673-.297-.98-.014-.497.459-1 .913-1.5 1.369L5.6 3.408a.55.55 0 0 0-.202.405 81.69 81.69 0 0 0 0 1.636c.003.324.266.55.625.551Z'
      fill={props.fill}
    />
  </Svg>
)

Icon.defaultProps = {
  fill: 'white',
}

export default Icon
