import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 23 21' {...props}>
    <path
      d='M9.16513 16.8051C13.5317 16.8051 17.0715 13.267 17.0715 8.90255C17.0715 4.53809 13.5317 1 9.16513 1C4.79858 1 1.25879 4.53809 1.25879 8.90255C1.25879 13.267 4.79858 16.8051 9.16513 16.8051Z'
      stroke='white'
      stroke-miterlimit='10'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M10.0666 11.8174L7.13842 6.4086C6.78118 5.74873 7.02453 4.93157 7.68472 4.57416C8.3449 4.21674 9.16218 4.4597 9.51942 5.11958L12.0377 9.77112L16.2585 7.48604C16.9187 7.12863 17.736 7.37159 18.0932 8.03146L20.4768 12.4342C20.711 12.8669 20.7437 13.3668 20.588 13.8288C20.4115 14.33 20.0794 14.9574 19.471 15.3846C19.3312 15.4883 19.2933 15.6767 19.3694 15.8173L19.7794 16.5746C19.8555 16.7152 19.8009 16.8986 19.6602 16.9748L14.8982 19.5528C14.7467 19.6349 14.574 19.5745 14.492 19.423C14.2434 18.8862 13.5878 17.8563 12.4695 18.3498C11.8202 18.7013 10.6437 18.0793 9.88241 16.673L7.50472 12.2811C7.35831 12.0107 7.44592 11.6555 7.72235 11.5198C8.9895 10.8618 9.75074 11.4149 10.0666 11.8174Z'
      fill='#1A1A1A'
      stroke='white'
      stroke-miterlimit='10'
    />
    <path d='M9.55615 10.9747L11.3131 14.22' stroke='white' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round' />
  </Svg>
)

Icon.defaultProps = {
  fill: 'transparent',
}

export default Icon
