import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 20 20' {...props} fill='none'>
    <path
      d='M12.2422 11.4167C13.4143 10.718 14.1996 9.43618 14.1996 7.96956C14.1996 5.75229 12.4038 3.95654 10.1866 3.95654C7.96931 3.95654 6.17356 5.75229 6.17356 7.96956C6.17356 9.43618 6.95884 10.7123 8.13099 11.4167C6.2544 12.2886 4.93213 14.3211 4.93213 16.6942C4.93213 17.1273 4.97832 17.5546 5.05916 17.9588C6.52001 18.9404 8.28689 19.5178 10.1808 19.5178C12.0747 19.5178 13.8416 18.9404 15.3024 17.9588C15.3891 17.5488 15.4295 17.1273 15.4295 16.6942C15.441 14.3211 14.1245 12.2886 12.2422 11.4167Z'
      fill='white'
    />
    <path
      d='M10.1924 19.3848C15.2692 19.3848 19.3848 15.2692 19.3848 10.1924C19.3848 5.11558 15.2692 1 10.1924 1C5.11558 1 1 5.11558 1 10.1924C1 15.2692 5.11558 19.3848 10.1924 19.3848Z'
      stroke='white'
      strokeMiterlimit='10'
    />
  </Svg>
)

Icon.defaultProps = {
  fill: 'white',
}

export default Icon
