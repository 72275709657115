import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 14 9' {...props} fill='none'>
    <path
      d='M8.46551 8.10543C6.78032 8.10432 5.09514 8.10542 3.41049 8.10432C3.00583 8.10432 2.85976 7.95532 2.85976 7.53603C2.85814 5.63564 2.85868 3.73524 2.85976 1.8343C2.85976 1.75952 2.87275 1.6853 2.88465 1.5568C3.2931 1.97997 3.65773 2.36326 4.02776 2.73935C4.25823 2.97309 4.50438 2.99137 4.69589 2.8025C4.88415 2.61694 4.8755 2.34221 4.6591 2.11789C4.03155 1.46873 3.40184 0.821789 2.76671 0.180386C2.52489 -0.063879 2.30525 -0.0583401 2.0591 0.186479C1.77454 0.468962 1.49701 0.7592 1.21678 1.04667C0.860262 1.41223 0.497798 1.77226 0.1494 2.14558C-0.103784 2.41699 -0.024799 2.82133 0.30791 2.88835C0.45506 2.9177 0.671997 2.85013 0.783982 2.74489C1.17295 2.37877 1.53001 1.9772 1.89896 1.58892C1.92439 1.60277 1.94928 1.61607 1.9747 1.62991C1.9747 1.73017 1.9747 1.83042 1.9747 1.93123C1.9747 3.79506 1.97308 5.65835 1.9747 7.52218C1.97579 8.45659 2.50433 8.99774 3.41969 8.99885C5.08648 9.00106 6.75382 8.9994 8.42061 8.9994C8.4839 8.9994 8.5472 9.00217 8.60942 8.99552C8.85665 8.96894 9.01462 8.77563 8.99893 8.52306C8.98324 8.25331 8.79822 8.10598 8.46551 8.10543Z'
      fill='white'
    />
    <path
      d='M13.8682 6.20862C13.6905 6.02691 13.4263 6.033 13.2183 6.24019C12.8764 6.58089 12.543 6.93101 12.2043 7.27503C12.1584 7.32157 12.1028 7.35868 12.0169 7.42849C12.0169 7.27947 12.0169 7.17975 12.0169 7.08059C12.0163 5.18873 12.018 3.29688 12.0142 1.40502C12.0126 0.558537 11.4686 0.00510768 10.6426 0.00344573C9.1667 0.000675813 7.69083 0.00233776 6.21496 0.00233776C5.94485 0.00233776 5.67474 -0.00541801 5.40517 0.00732362C5.17504 0.0178493 5.00163 0.217283 5.00001 0.443863C4.99839 0.66878 5.1718 0.865444 5.39923 0.889819C5.47918 0.898683 5.5613 0.893697 5.64179 0.893697C7.27054 0.893697 8.89929 0.893697 10.528 0.893697C11.0137 0.893697 11.1444 1.03053 11.145 1.53853C11.145 2.10138 11.145 2.66423 11.145 3.22708C11.145 4.61093 11.145 5.99478 11.145 7.46948C10.7101 7.02075 10.3352 6.62632 9.95271 6.24075C9.74797 6.03411 9.48002 6.02691 9.30283 6.2064C9.12456 6.387 9.12834 6.66288 9.33038 6.87229C9.96351 7.52765 10.6004 8.18024 11.2406 8.8284C11.4659 9.05664 11.7008 9.05775 11.9272 8.8284C12.5674 8.18024 13.2032 7.5271 13.8358 6.87063C14.0465 6.65346 14.0513 6.39531 13.8682 6.20862Z'
      fill='white'
    />
  </Svg>
)

Icon.defaultProps = {
  fill: 'white',
}

export default Icon
