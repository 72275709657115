import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 17 13' {...props} fill='none'>
    <path
      d='M16.2426 1.37884C16.2415 0.290586 14.9513 -0.380001 14.0001 0.23315C13.9837 0.243731 13.9672 0.254815 13.9508 0.265899L8.78097 3.8783C8.3884 4.15238 7.85258 4.15238 7.46001 3.8783L2.2918 0.265899C2.27535 0.254311 2.25943 0.243731 2.24246 0.23315C1.29179 -0.380001 0.00159152 0.290586 0 1.37884V11.6886C0 12.413 0.618042 13 1.38091 13H14.8691C15.632 13 16.2505 12.4125 16.25 11.688L16.2447 1.3738L16.2426 1.37884ZM10.2675 11.151H3.34326C2.5666 11.151 1.93689 10.5524 1.93795 9.81484L1.94166 5.95455C1.94272 5.29958 2.71514 4.89653 3.28491 5.26533C3.29499 5.27187 3.30454 5.27842 3.31409 5.28497L6.17883 7.29623C6.55125 7.55772 7.05894 7.55772 7.43136 7.29623L10.2961 5.28497C10.3057 5.27792 10.3157 5.27137 10.3253 5.26533C10.895 4.89653 11.668 5.30009 11.6685 5.95455L11.6722 9.81484C11.6733 10.5524 11.0441 11.151 10.2675 11.151Z'
      fill='white'
    />
  </Svg>
)

Icon.defaultProps = {
  fill: 'transparent',
}

export default Icon
