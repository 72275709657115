import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
    <Svg viewBox="0 0 15 19" fill="none" {...props}>
        <path d="M9 0C4.02676 0 0 4.02676 0 9C0 13.9732 4.02676 18 9 18C13.9732 18 18 13.9732 18 9C18 4.02676 13.9676 0 9 0ZM10.2265 13.9564C9.93528 14.2757 9.56005 14.4325 9.10641 14.4325C8.65837 14.4325 8.28874 14.2757 7.99751 13.9564C7.70629 13.6372 7.56067 13.2508 7.56067 12.7915C7.56067 12.3211 7.70628 11.9403 7.99191 11.6378C8.27754 11.3354 8.65277 11.1842 9.10641 11.1842C9.56005 11.1842 9.93528 11.3354 10.2265 11.6378C10.5177 11.9403 10.6633 12.3267 10.6633 12.7915C10.6689 13.2508 10.5177 13.6372 10.2265 13.9564ZM12.3771 7.21344C12.2483 7.54387 12.0971 7.8183 11.9179 8.04232C11.7386 8.26634 11.509 8.51836 11.229 8.79838C10.921 9.10081 10.6914 9.35843 10.5457 9.57125C10.4001 9.78407 10.3273 10.0305 10.3273 10.3105H7.8911C7.8911 9.78967 7.99191 9.36403 8.19353 9.0224C8.39515 8.68637 8.68637 8.31114 9.06721 7.9079C9.33043 7.62788 9.52645 7.39266 9.65526 7.19664C9.78407 7.00622 9.84568 6.799 9.84568 6.58059C9.84568 6.27816 9.73367 6.03734 9.50965 5.85252C9.28563 5.6733 8.9944 5.5781 8.64157 5.5781C8.27754 5.5781 7.94711 5.67891 7.65028 5.87492C7.35345 6.07094 7.11263 6.35096 6.93342 6.7038L4.88924 5.46049C5.26447 4.73242 5.79091 4.16677 6.47978 3.75793C7.16304 3.3547 7.99191 3.14748 8.9608 3.14748C10.0473 3.14748 10.921 3.4051 11.5818 3.92035C12.2427 4.43559 12.5731 5.15806 12.5731 6.08214C12.5675 6.50778 12.5059 6.88861 12.3771 7.21344Z" fill="white"/>
    </Svg>
  )
  
  Icon.defaultProps = {
    fill: 'white',
  }
  
  export default Icon