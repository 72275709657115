import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 13 13' {...props} fill='none'>
    <circle cx="6.5" cy="6.5" r="6.5" fill={props.fill}/>
    <path d="M5.03042 6.14386C5.32738 6.43645 5.60622 6.7457 5.94059 7.09659C6.41117 6.42979 6.80926 5.86237 7.21086 5.29717C7.53178 4.84468 7.85213 4.39219 8.18124 3.94526C8.41799 3.62324 8.57056 3.60048 8.91078 3.80979C9.72041 4.30725 9.73268 4.33279 9.20832 5.08564C8.38174 6.27266 7.54932 7.4558 6.71047 8.63505C6.33108 9.1686 6.05692 9.19414 5.56764 8.73887C4.90707 8.12371 4.25879 7.49633 3.6105 6.86895C3.34686 6.61411 3.35271 6.44089 3.60524 6.17273C4.2658 5.46984 4.3418 5.4654 5.03042 6.14386Z" fill="#1A1A1A"/>
  </Svg>
)

Icon.defaultProps = {
  fill: 'white',
}

export default Icon
