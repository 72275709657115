import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 13 15' {...props} fill='none'>
    <path
      d='M11.955 6.723c-.804.931-2.112 1.471-3.73 1.282-.23-.028-.345.045-.471.206-1.7 2.144-3.407 4.281-5.1 6.428-.206.26-.433.378-.773.359-.35-.02-.702-.003-1.053-.004-.29-.002-.466-.133-.507-.414-.105-.722-.218-1.442-.318-2.166-.042-.31.374-.901.721-1.051a.506.506 0 0 1 .128-.043c.862-.14 1.22-.614 1.316-1.445.08-.707.533-1.272 1.367-1.448.35-.073.707-.12.94-.487.187-.299.258-.542.107-.854-.056-.115-.067-.251-.124-.365-.188-.378-.12-.705.192-.993.152-.14.168-.287.098-.49C4.158 3.505 4.84 1.67 6.432.67c1.577-.99 3.675-.873 5.118.287 1.711 1.37 1.986 3.827.405 5.766ZM9.92 4.46a1.086 1.086 0 0 0-.226-1.593c-.52-.362-1.276-.257-1.666.23-.39.487-.28 1.195.24 1.568.535.385 1.248.295 1.652-.205Z'
      fill={props.fill}
    />
  </Svg>
)

Icon.defaultProps = {
  fill: 'white',
}

export default Icon
