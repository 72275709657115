import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 14 13' {...props} fill='none'>
    <path
      d='M6.784 12.998c-1.69 0-3.38-.002-5.071.002-.431.001-.813-.118-1.137-.407a1.733 1.733 0 0 1-.365-2.12c.306-.56.608-1.122.909-1.685C2.544 6.123 3.964 3.456 5.395.795c.257-.48.69-.743 1.234-.788.629-.052 1.138.181 1.493.706.139.205.24.434.358.653l3.222 6.03c.514.963 1.025 1.925 1.542 2.885.111.207.229.408.283.64.213.92-.392 1.878-1.3 2.05-.13.024-.265.027-.397.027H6.784Zm.004-8.91a.914.914 0 0 0-.921.794c-.017.131.003.269.016.403.058.605.12 1.209.18 1.813.055.556.11 1.113.163 1.67.027.286.26.51.544.515a.554.554 0 0 0 .568-.498c.048-.466.092-.933.139-1.4l.181-1.83c.02-.201.06-.405.05-.607a.916.916 0 0 0-.92-.86Zm-.562 6.127a.56.56 0 0 0 .556.556c.307.001.56-.254.558-.563a.56.56 0 0 0-.56-.552.559.559 0 0 0-.554.559Z'
      fill={props.fill}
    />
  </Svg>
)

Icon.defaultProps = {
  fill: 'white',
}

export default Icon
