import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg width='13' height='13' viewBox='0 0 13 13s' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13 6.59226C13 6.28335 13 5.97498 13 5.66607C12.9832 5.59688 12.959 5.52823 12.9509 5.45795C12.6793 3.03298 10.8796 0.974674 8.3928 0.275676C7.93751 0.1471 7.45911 0.0898944 6.99111 0C6.66351 0 6.33649 0 6.00889 0C5.94476 0.0152548 5.8812 0.0359578 5.81591 0.0446748C3.16911 0.378101 1.35547 1.72325 0.399244 4.06541C0.1924 4.57209 0.129422 5.13106 0 5.66607C0 5.97498 0 6.28335 0 6.59226C0.0167561 6.66145 0.0410223 6.7301 0.0491114 6.80038C0.318356 9.21227 2.1164 11.2793 4.57889 11.9761C5.04342 12.1074 5.53164 12.1663 6.00889 12.2583C6.33649 12.2583 6.66351 12.2583 6.99111 12.2583C7.05524 12.2431 7.1188 12.2224 7.18409 12.2137C9.88 11.8715 11.7012 10.4871 12.6429 8.09268C12.8301 7.61596 12.8844 7.09349 13 6.59226ZM11.3816 7.6067C10.2862 8.39941 9.22711 9.16542 8.11431 9.97065C8.11431 9.40023 8.11431 8.90445 8.11431 8.37108C7.29098 8.37108 6.49076 8.37108 5.68533 8.37108C5.68533 7.84533 5.68533 7.35827 5.68533 6.83416C6.49711 6.83416 7.29791 6.83416 8.13107 6.83416C8.13107 6.31549 8.13107 5.83333 8.13107 5.30813C9.23347 6.08776 10.2925 6.83633 11.3816 7.6067ZM4.87702 5.41709C4.87702 5.93903 4.87702 6.42228 4.87702 6.95619C3.76653 6.17057 2.70458 5.41873 1.62009 4.65108C2.71324 3.85947 3.77058 3.09346 4.88107 2.28931C4.88107 2.85047 4.88107 3.35224 4.88107 3.88344C5.7096 3.88344 6.50347 3.88344 7.30889 3.88344C7.30889 4.40755 7.30889 4.90115 7.30889 5.41709C6.50636 5.41709 5.72 5.41709 4.87702 5.41709Z'
      fill='#8B40F4'
    />
  </Svg>
)

Icon.defaultProps = {
  fill: 'white',
}

export default Icon
