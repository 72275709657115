import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
    <Svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.40195 16.3781C7.53789 16.4578 7.63164 16.4484 7.7582 16.3734C10.1441 14.9953 12.5301 13.6266 14.916 12.2531C14.9723 12.2203 15.0285 12.1781 15.0895 12.1359C12.6332 8.09062 10.191 4.05937 7.72539 0H7.71133C5.24102 4.07812 2.78945 8.11406 0.337891 12.1547C0.408203 12.2016 0.445703 12.225 0.487891 12.2484C2.79414 13.6266 5.10039 15 7.40195 16.3781Z" fill={props.fill}/>
        <path d="M15.1875 13.3873C12.7125 14.8076 10.2375 16.2326 7.76719 17.6576C7.60781 17.7514 7.5 17.7373 7.35 17.6436C4.97344 16.2186 2.59687 14.8029 0.220312 13.3873C0.173437 13.3592 0.117188 13.3404 0 13.2842C2.59219 16.8842 5.1375 20.4279 7.71094 23.9998H7.725C10.3125 20.4045 12.8672 16.8561 15.4266 13.3029C15.3234 13.3123 15.2531 13.3451 15.1875 13.3873Z" fill={props.fill}/>
    </Svg>
)

Icon.defaultProps = {
    fill: 'white',
}

export default Icon
    