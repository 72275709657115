import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 19 15' {...props} fill='none'>
   <path
      d="M18.245.37v.45c-.022.052-.059.112-.066.164-.882 4.344-1.757 8.681-2.632 13.018-.212 1.043-.802 1.283-1.64.652-1.16-.862-2.32-1.733-3.479-2.595a7.865 7.865 0 0 0-.365-.263c-.714.698-1.414 1.373-2.107 2.048-.532.518-.999.398-1.232-.315-.489-1.493-.977-2.979-1.451-4.472-.073-.225-.183-.345-.401-.412-1.16-.36-2.326-.713-3.471-1.11-.24-.083-.438-.316-.656-.48v-.376c.211-.158.4-.368.634-.458 5.286-2.04 10.573-4.066 15.86-6.1.524-.202.648-.172 1.006.248ZM7.293 12.29c.021-.007.043-.007.072-.015.073-.683.168-1.365.212-2.048.022-.293.138-.473.343-.66 2.318-2.146 4.637-4.292 6.949-6.453.102-.097.13-.277.197-.42-.14.008-.314-.03-.416.038-2.778 1.808-5.542 3.63-8.32 5.454-.16.105-.19.203-.131.398.32 1.058.634 2.123.948 3.18.043.18.095.353.146.526Z"
      fill={props.fill}
    />
  </Svg>
)

Icon.defaultProps = {
  fill: 'white',
}

export default Icon
