import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
    <Svg width="40" height="34" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M39.1975 10.663C37.5369 8.8822 35.97 6.97646 34.1196 5.41437C29.2146 1.27604 23.5046 -0.665748 17.088 0.204213C10.0178 1.16069 4.74754 5.15963 0.474634 10.6558C-0.155007 11.4681 -0.16462 12.2731 0.484247 13.0566C1.2749 14.0131 2.04874 14.9888 2.90908 15.8779C7.54247 20.6651 13.0362 23.6139 19.9863 23.7148C20.652 23.6716 21.4547 23.6595 22.2453 23.5586C29.4862 22.6214 34.8501 18.6368 39.2192 13.0542C39.8464 12.2539 39.8969 11.4128 39.1975 10.663ZM19.8878 20.0764C15.2712 20.0812 11.6592 16.522 11.6135 11.9247C11.5678 7.31531 15.264 3.58793 19.8613 3.60956C24.4346 3.63119 28.0563 7.26244 28.0779 11.8454C28.0971 16.4187 24.4635 20.0691 19.8878 20.0764Z" fill="white"/>
        <path d="M20.0468 6.83984C20.1501 7.24358 20.2078 7.66655 20.203 8.10153C20.1741 10.846 17.8358 13.1531 15.1154 13.1146C15.0673 13.1146 15.0193 13.1074 14.9712 13.105C15.5335 15.2439 17.497 16.878 19.804 16.9093C22.5245 16.9453 24.8628 14.6407 24.8916 11.8962C24.9205 9.18778 22.7456 6.94078 20.0468 6.83984Z" fill="white"/>
    </Svg>
)

Icon.defaultProps = {
    fill: 'white',
}

export default Icon
    