import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 32 36' {...props} fill='none'>
    <path
      d='M12.742 15.102c-1.026 0-1.836.9-1.836 1.998 0 1.098.828 1.998 1.836 1.998 1.026 0 1.836-.9 1.836-1.998.018-1.098-.81-1.998-1.836-1.998Zm6.57 0c-1.026 0-1.836.9-1.836 1.998 0 1.098.828 1.998 1.836 1.998 1.026 0 1.836-.9 1.836-1.998 0-1.098-.81-1.998-1.836-1.998Z'
      fill={props.fill}
    />
    <path
      d='M28.06 0H3.94A3.699 3.699 0 0 0 .25 3.708v24.336a3.699 3.699 0 0 0 3.69 3.708h20.412l-.954-3.33 2.304 2.142 2.178 2.016L31.75 36V3.708A3.699 3.699 0 0 0 28.06 0Zm-6.948 23.508s-.648-.774-1.188-1.458c2.358-.666 3.258-2.142 3.258-2.142a10.3 10.3 0 0 1-2.07 1.062c-.9.378-1.764.63-2.61.774a12.61 12.61 0 0 1-4.662-.018 15.108 15.108 0 0 1-2.646-.774 10.54 10.54 0 0 1-1.314-.612c-.054-.036-.108-.054-.162-.09-.036-.018-.054-.036-.072-.054-.324-.18-.504-.306-.504-.306s.864 1.44 3.15 2.124c-.54.684-1.206 1.494-1.206 1.494-3.978-.126-5.49-2.736-5.49-2.736 0-5.796 2.592-10.494 2.592-10.494 2.592-1.944 5.058-1.89 5.058-1.89l.18.216c-3.24.936-4.734 2.358-4.734 2.358s.396-.216 1.062-.522c1.926-.846 3.456-1.08 4.086-1.134.108-.018.198-.036.306-.036 3.121-.422 6.296.17 9.054 1.692 0 0-1.422-1.35-4.482-2.286l.252-.288s2.466-.054 5.058 1.89c0 0 2.592 4.698 2.592 10.494 0 0-1.53 2.61-5.508 2.736Z'
      fill={props.fill}
    />
  </Svg>
)

Icon.defaultProps = {
  fill: 'white',
}

export default Icon
