import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 15 9' {...props} fill='none'>
    <path
      d='M14.808 4.028c-.627-.672-1.22-1.392-1.918-1.983C11.037.482 8.88-.252 6.455.077 3.785.438 1.794 1.95.18 4.026c-.238.306-.241.61.004.906.299.362.591.73.916 1.066 1.75 1.809 3.826 2.923 6.451 2.96.252-.015.555-.02.854-.058 2.735-.354 4.762-1.86 6.412-3.968.237-.303.256-.62-.008-.904ZM7.513 7.584c-1.744.002-3.108-1.342-3.126-3.08a3.098 3.098 0 0 1 3.116-3.14 3.101 3.101 0 0 1 3.104 3.11 3.08 3.08 0 0 1-3.094 3.11Z'
      fill={props.fill}
    />
    <path
      d='M7.573 2.584c.04.152.06.312.06.476C7.62 4.097 6.737 4.97 5.71 4.954l-.054-.003A1.935 1.935 0 0 0 7.48 6.388C8.51 6.4 9.392 5.53 9.403 4.494a1.904 1.904 0 0 0-1.83-1.91Z'
      fill={props.fill}
    />
  </Svg>
)

Icon.defaultProps = {
  fill: 'white',
}

export default Icon
