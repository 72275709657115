import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 73 83' {...props} fill='none'>
    <g filter='url(#a)' fill={props.fill}>
      <path d='M68.73 24.357v-3.799c0-.192.006-.384 0-.575a2.831 2.831 0 0 0-.802-1.932c-.117-.122-.237-.242-.356-.365l-4.692-4.784c-2.254-2.3-4.51-4.598-6.764-6.897l-4.712-4.806c-.125-.128-.248-.259-.376-.384-.698-.684-1.51-.804-2.397-.804H11.266c-1.514 0-3.027-.024-4.537 0h-.061c-1.442 0-2.664 1.22-2.664 2.664v66.831c0 .903-.008 1.806 0 2.71v.117c0 1.44 1.22 2.663 2.664 2.663H63.652c.773 0 1.546.008 2.319 0h.098c1.441 0 2.664-1.22 2.664-2.663V26.237c-.003-.627.005-1.253-.003-1.881Zm-15.77-13.96 4.893 4.99 4.233 4.316c.44.447.877.9 1.316 1.35v.56H46.678V5.34h1.321l.917.935c1.348 1.375 2.696 2.75 4.044 4.122Zm.799 59.277H9.329V5.344h30.086c.646 0 1.29.005 1.935.008v18.93c0 1.441 1.22 2.664 2.664 2.664h19.388V69.676h-3.247c-2.134-.002-4.265-.002-6.396-.002Z' />
      <path d='M31.471 34.735c0-.575-.73-.879-1.137-.471L27.297 37.3l-4.838 4.838-.914.913h-6.42a.617.617 0 0 0-.471.2l-.014.013a.61.61 0 0 0-.18.453v9.474c0 .36.306.665.665.665H21.7l2.787 2.787 4.755 4.755 1.092 1.092c.407.408 1.137.107 1.137-.471V38.1c0-1.103.048-2.216 0-3.32-.002-.013 0-.029 0-.045ZM42.768 42.605a13.62 13.62 0 0 0-2.009-2.698 11.77 11.77 0 0 0-1.508-1.31c-.516-.387-1.46-.432-2.053-.27-.618.17-1.287.647-1.593 1.226-.328.618-.49 1.366-.27 2.053l.27.637c.191.322.436.589.735.807.41.355.786.754 1.13 1.172.394.541.737 1.12 1.02 1.727.189.509.335 1.03.431 1.566.046.525.046 1.05 0 1.575-.12.69-.314 1.36-.567 2.013a15.14 15.14 0 0 1-1.553 2.585c-.253.317-.512.626-.78.93-.472.53-.782 1.158-.782 1.883 0 .652.29 1.433.781 1.883.509.466 1.175.813 1.883.78.683-.029 1.415-.252 1.884-.78 1.646-1.849 3.008-3.87 3.841-6.212.557-1.57.8-3.33.592-4.987-.205-1.633-.648-3.136-1.452-4.58ZM51.425 34.946c-.503-.725-1.03-1.463-1.652-2.094-.975-1-2.802-1.052-3.767 0-.985 1.074-1.041 2.701 0 3.767.171.176.328.362.483.554a19.672 19.672 0 0 1 2.195 3.788 20.895 20.895 0 0 1 1.26 4.59c.189 1.638.181 3.293-.008 4.934-.304 2.006-.869 3.956-1.63 5.834a34.536 34.536 0 0 1-2.718 4.992c-.762 1.17-.274 2.994.957 3.645 1.326.7 2.831.293 3.644-.957 3.447-5.299 5.757-11.679 5.126-18.075-.384-3.897-1.642-7.742-3.89-10.978Z' />
    </g>
    <defs>
      <filter id='a' x={0} y={0} width={72.734} height={83} filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_211_361' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow_211_361' result='shape' />
      </filter>
    </defs>
  </Svg>
)

Icon.defaultProps = {
  fill: 'white',
}

export default Icon
