import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 18 15' {...props} fill='none'>
    <path
      d='M17.9918 1.59097C17.9906 0.335291 16.5615 -0.438463 15.5078 0.269019C15.4896 0.281227 15.4714 0.294017 15.4532 0.306806L9.72662 4.47497C9.29176 4.79121 8.69825 4.79121 8.26339 4.47497L2.5386 0.306806C2.52039 0.293435 2.50276 0.281227 2.48395 0.269019C1.4309 -0.438463 0.00176292 0.335291 0 1.59097V13.4868C0 14.3227 0.684601 15 1.52963 15H16.4704C17.3154 15 18.0006 14.3222 18 13.4862L17.9941 1.58516L17.9918 1.59097ZM11.3732 12.8665H3.70331C2.843 12.8665 2.14547 12.1759 2.14665 11.3248L2.15076 6.87064C2.15194 6.11491 3.00754 5.64984 3.63867 6.07538C3.64983 6.08293 3.66041 6.09049 3.67099 6.09805L6.84424 8.41873C7.25677 8.72044 7.81914 8.72044 8.23166 8.41873L11.4049 6.09805C11.4155 6.08991 11.4267 6.08235 11.4372 6.07538C12.0684 5.64984 12.9246 6.11549 12.9251 6.87064L12.9293 11.3248C12.9304 12.1759 12.2335 12.8665 11.3732 12.8665Z'
      fill='white'
    />
  </Svg>
)

Icon.defaultProps = {
  fill: 'white',
}

export default Icon
