import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 15 15' {...props} fill='none'>
    <path
      d='M14.8405 0.0121517L4.248 4.49293C4.2173 4.50827 4.20195 4.52362 4.1866 4.55431L0.0110171 14.8355C-0.0350371 14.9276 0.0724227 15.035 0.164531 14.989L10.757 10.6617C10.7877 10.6463 10.803 10.631 10.8184 10.6003L14.994 0.165603C15.0247 0.0581871 14.9326 -0.0338837 14.8405 0.0121517ZM7.5025 9.64889C6.30509 9.64889 5.33795 8.68214 5.33795 7.48522C5.33795 6.28831 6.30509 5.32156 7.5025 5.32156C8.69991 5.32156 9.66704 6.28831 9.66704 7.48522C9.66704 8.68214 8.69991 9.64889 7.5025 9.64889Z'
      fill='white'
    />
  </Svg>
)

Icon.defaultProps = {
  fill: 'transparent',
}

export default Icon
