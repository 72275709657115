import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 87 65' {...props} fill='none'>
    <path
      d='M86.588 42.948c0-11.319.016-22.637-.008-33.956-.012-5.62-3.32-8.894-8.894-8.894H43.337C31.755.094 20.177.09 8.596.098 3.423.102.024 3.474.016 8.701c-.023 15.77-.02 31.535 0 47.305.008 5.632 3.301 8.897 8.882 8.897 22.967.004 45.93.004 68.898 0 5.428 0 8.752-3.312 8.788-8.803.027-4.384.004-8.768.004-13.152ZM70.265 8.478c.074-1.845 1.036-2.928 2.885-3.046 1.49-.094 2.994-.067 4.493-.04 2.63.048 3.509.947 3.533 3.54.007.652 0 1.304 0 1.955 0 5.197-.17 5.365-5.354 5.365-5.31 0-5.565-.259-5.565-5.624 0-.718-.023-1.436.008-2.15ZM16.327 56.116c-.059 2.343-.993 3.379-3.273 3.481-1.562.07-3.132.079-4.69-.02-2.045-.125-2.908-1.079-2.955-3.112-.035-1.37-.012-2.735-.008-4.105.012-2.751.84-3.583 3.595-3.607.652-.008 1.303 0 1.954 0 5.08 0 5.377.302 5.377 5.412.004.644.02 1.3 0 1.95Zm0-21.476c-.086 2.21-1.036 3.187-3.245 3.265-1.366.051-2.736.035-4.106.008-2.759-.051-3.56-.883-3.575-3.654-.004-.585 0-1.173 0-1.758 0-5.275.153-5.424 5.522-5.424 5.067 0 5.404.337 5.404 5.412.004.718.028 1.437 0 2.15Zm.004-22.265c-.015 2.983-.875 3.858-3.795 3.877-.522.004-1.044 0-1.562 0-5.479 0-5.57-.086-5.57-5.553v-1.37c.009-3.12.786-3.909 3.922-3.944 1.173-.012 2.347-.032 3.52.004 2.445.07 3.422 1.048 3.481 3.465.028 1.174.012 2.347.004 3.52Zm48.604 47.104H21.681V5.558h43.254v53.921Zm10.84.138c-.522 0-1.044.004-1.562 0-3.061-.043-3.936-.926-3.952-3.988-.008-1.173-.024-2.347.004-3.52.055-2.36 1.005-3.32 3.32-3.356 1.37-.023 2.736-.012 4.105-.004 2.626.02 3.47.871 3.49 3.513.003.651 0 1.303 0 1.954-.004 5.118-.287 5.4-5.405 5.4Zm-.137-21.696c-5.051 0-5.381-.334-5.381-5.448 0-.78-.035-1.566.012-2.343.114-1.982 1.06-2.943 3.065-3.034 1.429-.062 2.865-.035 4.298-.015 2.731.039 3.532.875 3.544 3.677.004.522 0 1.044 0 1.562 0 5.51-.09 5.6-5.538 5.6Z'
      fill={props.fill}
    />
    <path
      d='M29.766 42.913c.043 2.516 1.923 3.772 4.09 2.7 7.068-3.48 14.12-6.986 21.114-10.612.828-.428 1.712-1.672 1.704-2.532-.008-.855-.915-2.064-1.75-2.496-6.88-3.567-13.82-7.017-20.77-10.447-2.552-1.26-4.38-.07-4.404 2.806-.031 3.399-.008 6.794-.008 10.192.008 3.466-.035 6.928.024 10.39Z'
      fill={props.fill}
    />
  </Svg>
)

Icon.defaultProps = {
  fill: 'white',
}

export default Icon
