import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => (
  <Svg viewBox='0 0 14 13' {...props} fill='none'>
    <path
      d='M7.195 2.632a5.099 5.099 0 0 1 2.374.27c2.239.8 3.666 3.055 3.423 5.414a5.196 5.196 0 0 1-4.387 4.619c-1.456.22-2.777-.118-3.949-1.007a1.037 1.037 0 0 0-.692-.237c-.844.017-1.69.01-2.534.005-.934-.005-1.428-.503-1.429-1.44C0 8.403 0 6.551.001 4.7c.001-.877.374-1.317 1.267-1.475.06-1.84.89-3.075 2.837-3.216 1.54-.11 2.376.657 3.09 2.622ZM3.9 7.78c-.001 2.178 1.751 3.938 3.915 3.932 2.15-.006 3.91-1.772 3.905-3.915A3.911 3.911 0 0 0 7.814 3.89 3.896 3.896 0 0 0 3.9 7.781Zm1.933-4.548c.086-.774-.164-1.364-.814-1.735-.582-.334-1.183-.297-1.733.088-.56.393-.747.961-.692 1.647h3.239Z'
      fill={props.fill}
    />
    <path
      d='M10.028 9.038c-.18.294-.334.55-.504.83-.767-.46-1.517-.907-2.26-1.364-.06-.036-.1-.15-.1-.229C7.157 7.27 7.16 6.263 7.16 5.23h.973c0 .72.023 1.446-.01 2.17-.017.371.088.586.426.759.501.255.971.573 1.479.879Z'
      fill={props.fill}
    />
  </Svg>
)

Icon.defaultProps = {
  fill: 'white',
}

export default Icon
